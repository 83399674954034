<template>
    <div class="mb20">
        <div class="title" >
            <slot></slot>
        </div>
        <div>
            <slot name="small-title"></slot>
        </div>
        <ul class="list" :class="{'normal':direction=='horizontal'}">
            <li v-for="item in list">
                    <span class="label">
                        {{item.label}}
                    </span>
                <viewer :images="typeof result[item.property]=='string'?[result[item.property]]:result[item.property]" v-if="item.type=='img'||item.type=='imgList'">
                <img :src="result[item.property]||noImage" alt="" v-if="item.type=='img'" >
                <img :src="item||noImage" alt="" v-else-if="item.type=='imgList'" v-for="item in result[item.property]" class="pr10 pb12">

                </viewer>
                <span v-else-if="item.type=='input'||item.type=='select'||item.type=='imgUpload'||item.type=='textArea'||item.type=='radioGroup'">
                    <el-input v-model="result[item.property]" v-if="item.type=='input'" size="small"></el-input>
                    <el-input v-model="result[item.property]" type="textarea" v-if="item.type=='textArea'" ></el-input>
                    <el-select
                            v-else-if="item.type === 'select'"
                            :multiple="false"
                            v-model="result[item.property]"
                            :placeholder="item.placeholder || '请选择'"
                            size="small"
                    >
                        <el-option v-for="(opt, j) in item.options" :key="j" :label="opt.label" :value="opt.value"/>
                    </el-select>
                    <imgUpLoad v-if="item.type=='imgUpload'" @getImgList="(url)=>{getImgList(url,item.property)}"></imgUpLoad>
                    <el-radio-group  v-model="result[item.property]" v-if="item.type=='radioGroup'">
                      <el-radio v-for="(opt, j) in item.options" :key="j" :label="opt.value"
                                  @change="changeRadio">{{
                            opt.label }}
                        </el-radio>
                        </el-radio-group>
                    </span>
                <span v-else>
                        {{result[item.property]||'-'}}
                    </span>
            </li>

        </ul>
    </div>
</template>

<script>
    import imgUpLoad from '@/components/imgUpLoad'
  export default {
    name: "index",
    props:{
      list:{
        type:Array,
        default:()=>[]
      },
      result:{
        type:Object,
        default:()=>{}
      },
      direction:{
        type:String,
        default:'row'
      }
    },
    data(){
      return{
        noImage:require('@/assets/images/noImage.png')
      }
    },
    components:{imgUpLoad},
    methods:{
      getImgList(url,property){
        this.result[property]=url
      },
      changeRadio(){
        this.$emit('changeRadio')
      },
    }

  }
</script>

<style scoped lang="scss">
    .title{
        font-weight: bold;
        padding-left:10px;
        border-left:2px solid #4d68ff;
    }
    .list {
        display: flex;
        flex-wrap: wrap;
        font-size: 15px;
        li {
            width: calc(calc(100% / 2) - 40px);
            padding: 10px;
            margin: 10px 10px 0 0;
            border-bottom: 1px solid #f2f2f2;
            color:#666;
            display: flex;
            align-items: center;
            &.special-li {
                width: calc(calc(100%) - 40px);
            }
        }
        &.normal{
            li{
                width: calc(100%);
            }
        }
        .label{
            flex:0 0 200px;
        }
        img{
            width: 50px;
            height:50px;
        }
    }
</style>
