<template>
  <div class="page1">
    <infoList v-if="result" :list="contractBaseList" :result="result">合同信息</infoList>
    <infoList v-if="result.tenantInfo" :list="basicBaseList" :result="result.tenantInfo">商户信息</infoList>
    <div class="title">证照信息</div>
    <div v-for="item in result.photoList" v-if="result.photoList">
      <infoList :list="photoBaseList" :result="item" v-if="item.tenantCardPhotoName == '营业执照'"></infoList>
      <infoList :list="photoBaseList2" :result="item" v-else></infoList>
    </div>
    <infoList v-if="result.boothInfo" :list="boothBaseList" :result="result.boothInfo">摊位信息</infoList>
    <div class="title">商户信息变更历史</div>
    <div v-for="item in result.tenantChangeList">
      <infoList :list="basicBaseChangeList" :result="item"></infoList>
    </div>
    <div class="title" v-if="$route.name !== 'RefundRent'">合同收费信息</div>
    <div v-if="$route.name !== 'RefundRent'">
      <ETable :tableTitle="tableTitle" :tableData="result.itemList" :needPagination="false" :needSelect="false"></ETable>
    </div>
    <div v-if="$route.name !== 'RefundRent'" style="text-align: right">合计:{{hj}}</div>
    <div class="title" v-if="$route.name !== 'RefundRent'">合同收费标准</div>
    <div v-if="$route.name !== 'RefundRent'">
      <ETable
        :tableTitle="tableTitle2"
        :tableData="result.chargeRate"
        :arraySpanMethod="arraySpanMethod"
        :needPagination="false"
        :needSelect="false"
        :tableRowClassName="tableRowClassName"
      ></ETable>
    </div>
<!--    <infoList v-if="result" :list="basicBaseChangeList" :result="result.tenantChangeInfo">商户信息变更历史</infoList>-->
<!--    <div class="title" v-if="$route.name!=='RefundRent'">合同收费标准</div>-->
<!--    <div v-if="$route.name!=='RefundRent'">-->
<!--      <ETable :tableTitle="tableTitle2" :tableData="result.itemList" :needPagination="false" :needSelect="false"> </ETable>-->
<!--    </div>-->
  </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import infoList from '@/components/infoList';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import vxRule from '@/assets/js/formValidate';
  import { otherMixin } from '@/components/mixin';
  import * as math from 'mathjs'
  export default {
    name: 'Login',
    mixins: [otherMixin],
    data() {
      return {
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        hj:0,
        labelWidth: '100px',
        photoInfoFormColumns: [
          {
            title: '证照编号：',
            type: 'input',
            property: 'placeName',
            placeholder: '',
            show: true,
          },
          {
            title: '有效期自：',
            type: 'datePicker',
            property: 'validBeginDate',
            show: true,
          },
          {
            title: '证照名称：',
            type: 'select',
            property: 'tenantCardPhotoName',
            placeholder: '',
            show: true,
            disabled: true,
            options: [
              {
                label: '营业执照',
                value: 1,
              },
            ],
          },
          {
            title: '有效期至：',
            type: 'datePicker',
            property: 'validEndDate',
            show: true,
          },
          {
            title: '法人代表:',
            type: 'input',
            property: 'placeName',
            placeholder: '',
            show: true,
          },
          {
            title: '身份证号码:',
            type: 'select',
            property: 'idCard',
            placeholder: '',
            show: true,
          },
          {
            title: ' 经营范围：',
            type: 'select',
            property: 'businessScopeId',
            placeholder: '',
            show: true,
          },
          {
            title: ' 类别：',
            type: 'radio',
            property: 'placeName',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1,
              },
              {
                label: '公司',
                value: 2,
              },
            ],
          },
          {
            title: ' 说明：',
            type: 'textarea',
            property: 'description',
            placeholder: '请输入说明',
            show: true,
          },
          {
            title: '  照片：',
            type: 'mixinUpLoad',
            property: 'photoUrl',
            placeholder: '',
            show: true,
          },
          {
            title: '状态：',
            type: 'select',
            property: 'status',
            placeholder: '',
            show: true,
            options: [
              {
                label: '设立中',
                value: 1,
              },
              {
                label: '变更中',
                value: 2,
              },
              {
                label: '注销中',
                value: 2,
              },
              {
                label: '开业',
                value: 2,
              },
              {
                label: '异地',
                value: 2,
              },
            ],
          },
        ],
        photoInfoForm: {
          businessScopeId: '',
          validBeginDate: '',
          validEndDate: '',
          tenantCardPhotoName: '',
          idCard: '',
          description: '',
          status: '',
          photoUrl: '',
        },
        licenceList: [],
        form: {
          contractId: '',
          tenantName: '',
          phone: '',
          signPerson: '',
          businessScopeId: '',
          idCard: '',
          tenantType: '',
          birthPlace: '',
          idCardPath: '',
        },
        dialogForm: {},
        //合同信息
        contractBaseList: [
          {
            label: '合同编号',
            property: 'contractCode',
          },
          {
            label: '合同类型',
            property: 'contractTypeCn',
          },
        ], //商户原始信息
        basicBaseList: [
          {
            label: '商户名称',
            property: 'tenantName',
          },
          {
            label: '统一社会信用代码',
            property: 'socialCreditCode',
          },
          {
            label: '营业执照地址',
            property: 'businessLicenseAdress',
          },
          {
            label: '开户行及其账号',
            property: 'bankAccount',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '商户类型',
            property: 'tenantTypeCn',
          },
          {
            label: '经营范围',
            property: 'businessScopeName',
          },
          {
            label: '负责人姓名',
            property: 'signPerson',
          },
          {
            label: '联系电话',
            property: 'phone',
          },
          {
            label: '负责人籍贯',
            property: 'birthPlace',
          },
          {
            label: '录入人',
            property: 'createUserCn',
          },
          {
            label: '录入时间',
            property: 'createTime',
          },
          {
            label: '最近一次修改人',
            property: 'updateUserCn',
          },
          {
            label: '最近一次修改时间',
            property: 'updateTime',
          },
          {
            label: '身份证照片',
            property: 'idCardPath',
            type: 'imgList',
          },
        ], //变更基本信息
        basicBaseChangeList: [
          {
            label: '商户名称',
            property: 'tenantName',
          },
          {
            label: '统一社会信用代码',
            property: 'socialCreditCode',
          },
          {
            label: '营业执照地址',
            property: 'businessLicenseAdress',
          },
          {
            label: '开户行及其账号',
            property: 'bankAccount',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '商户类型',
            property: 'tenantTypeCn',
          },
          {
            label: '经营范围',
            property: 'businessScopeName',
          },
          {
            label: '负责人姓名',
            property: 'signPerson',
          },
          {
            label: '联系电话',
            property: 'phone',
          },
          {
            label: '负责人籍贯',
            property: 'birthPlace',
          },
          {
            label: '最近一次修改人',
            property: 'updateUserCn',
          },
          {
            label: '最近一次修改时间',
            property: 'updateTime',
          },
          {
            label: '变更原因',
            property: 'changeReason',
          },
          {
            label: '身份证照片',
            property: 'idCardPath',
            type: 'imgList',
          },
        ], //基本信息
        boothBaseList: [
          //摊位信息
          {
            label: '摊位号',
            property: 'boothCode',
          },
          {
            label: '状态',
            property: 'contractStatusCn',
          },
          {
            label: '用途',
            property: 'boothUseName',
          },
          {
            label: '面积',
            property: 'areas',
          },
          {
            label: '摊位类型',
            property: 'boothTypeName',
          },
          {
            label: '区域',
            property: 'areaName',
          },
          {
            label: '位置',
            property: 'placeName',
          },
          {
            label: '主营业务',
            property: 'rentPurposeName',
          },
          {
            label: '生效日期',
            property: 'inDate',
          },
          {
            label: '到期期自',
            property: 'expireDate',
          },
          {
            label: '交费方式',
            property: 'payTypeCn',
          },
        ],
        result: {
          photoList: [],
          tenantName: '',
          boothCode: '',
        },
        //证照经营
        photoBaseList: [
          {
            label: '名称',
            property: 'operatorName',
          },
          {
            label: '证照名称',
            property: 'tenantCardPhotoName',
          },
          {
            label: '统一社会信用代码',
            property: 'idCard',
          },
          {
            label: '住所',
            property: 'address',
          },
          // {
          //   label: '身份证号码',
          //   property: 'idCard',
          // },
          {
            label: '类别',
            property: 'ttypeCn',
          },
          {
            label: '法人姓名',
            property: 'corporationPerson',
          },
          {
            label: '经营项目',
            property: 'businessScope',
          },
          {
            label: '签发日期',
            property: 'issuerDate',
          },
          {
            label: '注册资本',
            property: 'registeredCapital',
          },
          {
            label: '经营期限',
            property: 'validDateStr',
          },
          {
            label: '成立日期',
            property: 'registerDate',
          },
          {
            label: '照片',
            property: 'photoUrl',
            type: 'imgList',
          },
        ],
        //食品证照
        photoBaseList2: [
          {
            label: '经营者名称',
            property: 'operatorName',
          },
          {
            label: '证照名称',
            property: 'tenantCardPhotoName',
          },
          {
            label: '统一社会信用代码（身份证号码）',
            property: 'idCard',
          },
          {
            label: '许可证编号',
            property: 'tenantCardPhotoCode',
          },
          {
            label: '住所',
            property: 'address',
          },
          {
            label: '日常监督管理机构',
            property: 'superviseOrganization',
          },
          {
            label: '经营场所',
            property: 'premises',
          },
          {
            label: '日常监督管理人员',
            property: 'supervisePerson',
          },
          {
            label: '主体业态',
            property: 'mainWork',
          },
          {
            label: '投诉举报电话',
            property: 'lineTelephone',
          },
          {
            label: '经营项目',
            property: 'businessScope',
          },
          {
            label: '发证机关',
            property: 'registerOffice',
          },
          {
            label: '签发日期',
            property: 'issuerDate',
          },
          {
            label: '签发人',
            property: 'issuerPerson',
          },
          {
            label: '有效期至',
            property: 'validEndDate',
          },
          {
            label: '照片',
            property: 'photoUrl',
            type: 'imgList',
          },
        ],
        tableData: [],
        tableTitle: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '年',
            prop: 'year',
          },
          {
            label: '月',
            prop: 'month',
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
          {
            label: '第几期',
            prop: 'periods',
          },
          {
            label: '是否到账',
            prop: 'isPayCn',
          },
          {
            label: '是否打印交费单',
            prop: 'isPrintCn',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
          },
          {
            label: '备注',
            prop: 'remark',
          },
        ],
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName',
          },
          {
            label: '金额',
            prop: 'amount',
          },
          {
            label: '单位',
            prop: 'chargeType',
          },
          {
            label: '合计（年）',
            prop: 'totalAmount',
          },
        ],
      };
    },
    watch: {},
    created() {
      this.form.contractId = this.$route.query.contractId;
      this.contractDetail();
    },
    components: { ETable, EButton, EForm, infoList },
    beforeMount() {},
    methods: {
      //合计行合并列
      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        if (this.result.chargeRate.length == rowIndex + 1) {
          if (columnIndex === 1) {
            console.log(456, columnIndex, this.result.chargeRate.length, rowIndex);
            return [1, 4];
          }
        }
      },
      //合计行居中
      tableRowClassName({ row, rowIndex }) {
        if (this.result.chargeRate.length == rowIndex + 1) {
          return 'center';
        }
        return '';
      },
      cancelDialog() {
        this.dialogVisible = false;
        this.dialogForm = {
          photoName: '',
        };
        this.$refs.form.$refs.form.resetFields();
      },
      onSummit(tenantCardPhotoName) {
        console.log(tenantCardPhotoName);
        this.photoInfoForm.tenantCardPhotoName = tenantCardPhotoName;
        this.dialogVisible = false;
        this.licenceList.push(this.photoInfoFormColumns);
      },
      //获取合同详情
      async contractDetail() {
        console.log(this.id);
        let res = await Http.contractDetail({ id: this.form.contractId });
        if (res.code == 200) {
          this.result = res.data;
          const list = this.result.chargeRate;
          let sum = 0;
          list.forEach((item) => {
            sum = sum + item.totalAmount;
          });
          const mxList = this.result.itemList;
          mxList.forEach((item) => {
            this.hj += item.payAmount;
          });
          this.result.chargeRate.push({ totalAmount: '', amount: null, takeReturnProjectName: '合计：' + math.round(sum, 2), chargeType: '' });
          this.result.boothInfo.payTypeCn = res.data.payTypeCn;
          this.result.tenantInfo.idCardPath = this.result.tenantInfo.idCardPath.split(',');
          this.result.photoList.forEach((item) => {
            item.photoUrl = item.photoUrl.split(',');
          });
          this.result.tenantChangeList.forEach((item, index) => {
            this.result.tenantChangeList[index].idCardPath = item.idCardPath.split(',');
          });
        }
      },
      saveData() {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          console.log(object);
          if (valid) {
            this.contractChangeoSubmit();
          } else {
            return false;
          }
        });
      },
      //更新合同
      async contractChangeoSubmit() {
        let res = await Http.contractChange(this.form);
        if (res.code == 200) {
          if (this.licenceList.length == 0) {
            //没有证照信息直接返回
            this.$message.success(res.msg);
            this.$router.push({ name: 'ContractList' });
          }
        }
      },
    },
  };
</script>

<style lang="scss">
.title {
  font-weight: bold;
  padding-left: 10px;
  border-left: 2px solid #4d68ff;
  margin-bottom: 20px;
}
.small-title {
  margin-left: 10px;
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.center {
  td:nth-child(2) {
    .cell {
      text-align: center;
    }
  }
}
</style>
